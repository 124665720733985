import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ITimesheetFormControls } from 'src/app/interfaces/timesheets';
import { EditNoteDialogComponent } from '../edit-note-dialog/edit-note-dialog.component';
import { WeekHours } from 'src/app/models/week-hours.model';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-timesheet-item',
    templateUrl: './timesheet-item.component.html',
    styleUrls: ['./timesheet-item.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatTooltipModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        NgClass,
        NgIf,
        MatButtonModule,
        TitleCasePipe,
    ],
})
export class TimesheetItemComponent implements OnInit {
    @Input() timesheetForm: FormGroup<ITimesheetFormControls>;
    @Input() weekHours: WeekHours;

    status: string = '';
    @Input() readOnly: boolean = false;
    @Output() updateTotalsEvent = new EventEmitter();
    @Output() onClickRemoveEvent = new EventEmitter<
        FormGroup<ITimesheetFormControls>
    >();
    @Output() unsavedHoursEvent = new EventEmitter();

    errorText = {
        oneDecimal: 'Hours may only have one decimal place',
        minimumValue: 'Hours must be 0 or greater',
        maximumValue: 'Hours must be less than 24',
    };

    totalHours = 0;

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {
        if (this.readOnly) {
            this.timesheetForm.controls.locked.setValue(true);
        }

        this.timesheetForm.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => {
                this.weekHours.locked = 0;
                this.weekHours.saved = 0;
                this.weekHours.submitted = 0;
                this.timesheetForm.controls.submitted.setValue(false);
                this.timesheetForm.controls.saved.setValue(false);
                this.unsavedHoursEvent.emit();
                this.calculateTotal();
            });

        this.calculateTotal();
    }

    private calculateTotal(): void {
        let newTotal = 0;

        newTotal += this.timesheetForm.controls.monday.value;
        newTotal += this.timesheetForm.controls.tuesday.value;
        newTotal += this.timesheetForm.controls.wednesday.value;
        newTotal += this.timesheetForm.controls.thursday.value;
        newTotal += this.timesheetForm.controls.friday.value;
        newTotal += this.timesheetForm.controls.saturday.value;
        newTotal += this.timesheetForm.controls.sunday.value;

        this.totalHours = newTotal;
        this.updateTotalsEvent.emit();
    }

    protected editNoteForDay(day: string): void {
        this.dialog.open(EditNoteDialogComponent, {
            height: '40%',
            width: '20%',
            data: {
                day: day + 'Notes',
                formGroup: this.timesheetForm,
                readOnly: this.timesheetForm.controls.locked.value,
            },
        });
    }

    tooltipText() {
        return `Client: ${this.timesheetForm.controls.clientName.value}
                Project: ${this.timesheetForm.controls.projectName.value}
                Task: ${this.timesheetForm.controls.taskName.value}
                `;
    }
}
